<template>
  <section class="section">
    <div class="details-header">
      <h1 class="title"><span v-text="instance.registration.name"/></h1>
      <h2 class="subtitle is-5">Id: <span v-text="instance.id"/></h2>
    </div>
    <hr>
    <div class="columns is-desktop">
      <div class="info-block">
        <div v-for="item in content" :key="item.name">
          <div
                class="card panel">
            <header class="card-header-title">
              <p class="card-header-title">{{ item.name }}</p>
            </header>
            <div class="card-content">
              <div class="content info">
                <table class="table">
                  <tr v-for="current in item.data"
                      :key="current">
                    <p v-for="(value, key) in current"
                       :key="key">{{ key }}: {{ value }}</p>
                    <hr>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    instance: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    content: []
  }),
  async created() {
    try {
      const r = await this.instance.axios.get("actuator/configurationmetadata");
      this.content = [
        {
          name: 'Groups',
          data: r.data.groups
        },
        {
          name: 'Properties',
          data: r.data.properties
        },
        {
          name: 'Hints',
          data: r.data.hints
        }
      ];
    } catch (err) {
      console.log("error fetching data:", err.message);
    }
  }
};
</script>
<style>
.info-block {
  width: 100%;
}
</style>