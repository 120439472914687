/* global SBA */

import configurationmetadata
  from './configurationmetadata/configurationmetadata.vue';
import envModule from './env/env-module.vue';
import baseConfigPage from './baseconfig/base-config-module.vue';

SBA.use({
  install({viewRegistry, axios}) {
    viewRegistry.addView({
      name: 'instances/custom',
      parent: 'instances',
      path: 'custom',
      component: configurationmetadata,
      label: 'Configuration metadata',
      group: 'custom',
      order: 1000,
      props: {"axios": axios}
    });
    viewRegistry.addView({
      name: 'instances/env',
      parent: 'instances',
      path: 'env',
      component: envModule,
      label: 'Окружение',
      group: 'insights',
      order: 100,
      props: {"axios": axios}
    });
    viewRegistry.addView({
      name: 'base-config',
      path: '/base-config',
      component: baseConfigPage,
      label: 'Дефолтный конфиг'
    });
  }
});

