<template>

  <sba-panel title="Переменное окружение"
             :key="configComponent">
    <div
        v-for="(prop, index) in managedProperties"
        :key="`managed-${index}`"
        class="flex gap-2 pb-2"
    >
      <sba-input
          v-model="prop.name"
          :list="allPropertyNames"
          :name="prop.name || 'new-prop-name'"
          class="flex-1"
          placeholder="Property name"
          type="text"
          @input="handlePropertyNameChange(prop, index)"
      />
      <sba-input
          v-model="prop.input"
          :name="prop.name || 'new-prop-value'"
          class="flex-1"
          placeholder="Value"
          type="text"
          @input="prop.status = null"
      >
        <template #append>
          <span v-if="prop.status === 'executing'">
            <font-awesome-icon
                :icon="['fas', 'sync-alt']"
                class="animate-spin"
            />
          </span>
          <span v-else-if="prop.status === 'failed'">
            <font-awesome-icon icon="exclamation-triangle"/>
          </span>
          <span
              v-else-if="prop.status === 'completed' || prop.input === prop.value"
          >
            <font-awesome-icon icon="check"/>
          </span>
          <span v-else-if="prop.input !== prop.value">
            <font-awesome-icon icon="pencil-alt"/>
          </span>
        </template>
      </sba-input>
    </div>
    <div class="flex gap-2 justify-end items-start">
      <sba-confirm-button
          :disabled="updateStatus === 'executing'"
          class="button is-primary"
          @click="updateEnvironment">
        <span v-text="$t('instances.env.context_update')"/>
      </sba-confirm-button>
    </div>
  </sba-panel>
  <sba-panel
      :header-sticks-below="'#subnavigation'"
      title="default-config.yml"
  >
    <div
        v-for="(prop, key) in this.properties"
        :key="`${key} + ${prop.name}`"
        class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
    >
      <dt class="text-sm font-medium text-gray-500">
        <span v-text="`${prop.name}:  `"/>
        <span v-if="prop.name" v-text="prop.value"/>
      </dt>
    </div>
  </sba-panel>

  <sba-modal v-model="isModalOpen" data-testid="refreshModal">
    <template #header>
      <span v-text="'Настройки обновлены'"/>
    </template>
    <template #body>
      <div
          v-for="(prop, key) in this.settings"
          :key="`${key} + ${prop.name}`"
          class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
      >
        <dt class="text-sm font-medium text-gray-500">
          <span v-text="`${prop.name}`"/>
        </dt>
      </div>
    </template>
    <template #footer>
      <button class="button is-success" @click="closeModal">
        {{ $t('term.ok') }}
      </button>
    </template>
  </sba-modal>
</template>

<script>

import axios from 'axios';
import yaml from 'js-yaml';
import {debounce, uniq} from 'lodash';
import {filter, from, map} from "../utils/rxjs.js";

export default {
  name: "base-config-module",
  props: {
    applications: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    scope: 'application',
    properties: [],
    resetStatus: null,
    updateStatus: null,
    managedProperties: [{
      name: null,
      input: null,
      value: null,
      status: null,
      validation: null
    }],
    configComponent: 0,
    isModalOpen: false,
    settings: []
  }),
  methods: {
    handlePropertyNameChange: debounce(function (prop, idx) {
      if (prop.name && idx === this.managedProperties.length - 1) {
        this.managedProperties.push({
          name: null,
          input: null,
          value: null,
          status: null,
          validation: null
        });
      }
    }, 250),
    ymlToPropertiesMap(obj, parentKey = '', properties) {
      let propertyName = '';
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let newKey = parentKey ? `${parentKey}.${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            propertyName += this.ymlToPropertiesMap(obj[key], newKey, properties);
          } else {
            propertyName += `${newKey}`;
            properties.push({
              name: propertyName,
              value: obj[key]
            });
            propertyName = '';
          }
        }
      }
    },
    updateManagedProperties(manager) {
      Object.entries(manager.properties).forEach(([name, property]) => {
        const managedProperty = this.managedProperties.find(property => property.name === name);
        if (managedProperty) {
          managedProperty.value = property.value
        } else {
          const idx = this.managedProperties.length - 1;
          this.managedProperties.splice(idx, 0, {
            name,
            input: property.value,
            value: property.value,
            status: null,
            validation: null
          })
        }
      });
    },
    updateEnvironment() {
      this.settings = [];
      from(this.managedProperties)
      .pipe(
          filter(property => !!property.name && property.input !== property.value && property.input
              !== null),
          map(
              property => {
                this.settings.push({name: property.name, value: property.input});
              }
          )
      ).subscribe({});

      this.gitEnvUpdate(this.settings, "default-config.yml").then(response => {
        this.updateStatus = "completed"
        this.loadDefaultConfig();
        this.updateEnvAllInstance();
        this.managedProperties = [{
          name: null,
          input: null,
          value: null,
          status: null,
          validation: null
        }];
        this.isModalOpen = true;
        this.refreshComponent(this.configComponent);
      })
      .catch(error => {
        this.$emit('update');
      });
    },

    async gitEnvUpdate(envInfoList, resource) {
      return axios.post(`/env/git/update`, {envInfoList, resource}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    },
    async loadDefaultConfig() {
      try {
        this.properties = [];
        const r = await axios.get('/config/default-config.yml', null);
        const obj = yaml.load(r.data);
        this.ymlToPropertiesMap(obj, '', this.properties)
      } catch (err) {
        console.log("error fetching data:", err.message);
      }
    },

    refreshComponent(keyComponent) {
      if (keyComponent > 0) {
        keyComponent -= 1;
      } else {
        keyComponent += 1;
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async getEnvInstance(thisAxios) {
      return thisAxios.get('actuator/env');
    },

    updateEnvAllInstance() {
      this.applications.forEach(application => {
        application.instances.forEach(instance => {
          var endpointEnv = instance.endpoints.find(ep => ep.id === 'env');
          if (endpointEnv != undefined) {
            if (this.getEnvInstance(instance.axios).then(response => {
              var defaultConfigProps = response.data.propertySources.find(source => {
                if (source.name.includes('default-config')) {
                  return source;
                }
                return undefined;
              });
              if (defaultConfigProps != undefined) {
                return true;
              }
              return false;
            }).catch(e => false)) {

              this.settings.forEach(setting => {
                instance.setEnv(setting.name, setting.input)
              });
              var endpointRefresh = instance.endpoints.find(ep => ep.id === 'refresh');
              if (endpointRefresh !== undefined) {
                instance.refreshContext();
              }
            }
          }
        });
      });
    }
  },
  computed: {
    allPropertyNames() {
      return uniq(this.properties.map(ps => ps.name)
      .sort());
    },
    managerPropertySource() {
      return {
        name: 'manager',
        properties: {}
      };
    },
    hasManagedProperty() {
      return this.managedProperties.findIndex(property => !!property.name) >= 0;
    },
  },
  async created() {
    await this.loadDefaultConfig();
  },

  watch: {
    managerPropertySource: {
      handler: 'updateManagedProperties',
      immediate: true
    },
    managedProperties: {
      deep: true,
      handler() {
        const counts = this.managedProperties.reduce(
            (acc, v) => {
              if (v.name) {
                acc[v.name] = (acc[v.name] || 0) + 1;
              }
              return acc;
            }, {});
        this.managedProperties.forEach(property => {
          if (!property.name) {
            if (property.input) {
              property.validation = 'Property name is required';
            }
            return;
          }
          const count = counts[property.name] || 0;
          if (count > 1) {
            property.validation = 'Property name must be unique';
            return;
          }
          property.validation = null;
        });
      }
    }
  }
}
</script>